<template>
  <div class="row no-gutters">
    <div class="col-md-12">
      <div
        class="row no-gutters img-uploader"
        :class="[multipleFiles ? 'multiple' : '', fileThumb ? 'no-preview' : '']"
      >
        <div
          class="img-uploader-outer"
          :class="fileThumb ? 'col-md-2' : 'col-md-2'"
          v-for="(item, index) in imagesList"
          :key="index"
        >
          <div class="img-uploader-inner shadow">
            <div class="img-uploader-img-wrap">
              <img width="100%" :src="accept !='image/*' ? fileThumb : item.url" />
            </div>
            <p class="img-uploader-file-name">{{item.name}}</p>
          </div>
        </div>
        <div v-if="currentImg" class="img-uploader-outer" :class="fileThumb ? 'col-md-2' : 'col-md-2'">
          <div class="img-uploader-inner shadow">
            <div class="img-uploader-img-wrap">
              <img width="100%" :src="currentImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <button type="button" class="btn btn-success" @click="trggerInput">
        <i class="fas fa-plus-square"></i>
        {{buttonText}}
      </button>
      <input
        ref="imageInput"
        @change="previewImages"
        type="file"
        :accept="accept"
        :id="inputId"
        :name="inputName"
        :multiple="multipleFiles"
        hidden
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imagesList: []
    };
  },
  mounted(){

  },
  props: {
    inputName: {
      default: "image_list",
      type: String
    },
    inputId: {
      default: "image_list",
      type: String
    },
    multipleFiles: {
      default: false,
      type: Boolean
    },
    accept: {
      default: "image/*",
      type: String
    },
    buttonText: {
      default: "Add Images",
      type: String
    },
    fileThumb: {
      type: String
    },
    currentImg: {
      type: String,

    }
  },
  methods: {
    trggerInput() {
      this.$refs.imageInput.click();
    },
    previewImages(e) {
      this.imagesList = [];
      this.currentImg = false;
      let images = e.target.files;
      //console.log(e);
      for (let i = 0; i < images.length; i++) {
        // console.log(images[i]);
        let reader = new FileReader();

        reader.addEventListener(
          "load",
          () => {
            this.imagesList.push({
              url: reader.result,
              name: images[i].name
            });
          },
          false
        );

        if (images[i]) {
          reader.readAsDataURL(images[i]);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.img-uploader {
  margin-bottom: 10px;

  &.multiple {
    max-height: 50vh;
    overflow: auto;
    background-color: #eee;
    .img-uploader-outer {
      padding: 1rem !important;
    }
  }
  &-inner {
    border: solid 5px #fff;
  }

  &-img-wrap {
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-color: #000;
    img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  &.no-preview {
    .img-uploader-inner {
      border: none !important;
      box-shadow: none !important;
    }
    .img-uploader-img-wrap {
      background-color: transparent;
    }
  }
  &-file-name {
    font-size: 12px;
    margin: 0;
    padding: 5px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
